@import "./_media-queries.scss";

.norisuke {
    .btn {
        &:not(:disabled):not(.disabled) {
            cursor: pointer;
        }
    }

    .container {
        max-width: 1366px !important;
        padding: 0;

        main {
            width: 100%;
            min-height: 100vh;
        }

        @media (min-width: 900px) and (max-width: 1399px) {
            max-width: 100%;

            .main {
                .top-page {
                    padding: 30px;
                }
            }
        }

        @media (max-width: 900px) {
            max-width: 100%;

            .main {
                .container-page {
                    margin: 0;
                }

                .top-page {
                    padding: 22px;
                    font-size: 22px;
                }
            }
        }
    }
}

button {
    position: relative;
    padding: 12px;
    overflow: hidden;
    background-color: dodgerblue;
    border: none;
    outline: none;
    color: white;
    .dzb-overlay-btn {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        pointer-events: none;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        animation: spread 0.6s linear infinite;
    }
}

.btn-disable:active {
    opacity: 0.65 !important;
}

@keyframes spread {
    0% {
        width: 0px;
        height: 0px;
        opacity: 0.4;
    }
    100% {
        width: 700px;
        height: 700px;
        opacity: 0;
    }
}
