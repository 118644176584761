@import "styles/scss/_media-queries.scss";
@import "styles/scss/Color.scss";

.container-page {
  width: 100%;
  max-width: 1111px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  justify-content: start;

  @include respond-below(lg) {
    max-width: 950px;
  }

  @include respond-below(md) {
    max-width: 760px;
  }

  @include respond-below(sm) {
    max-width: 645px;
    padding: 0 18px;
  }
}

.required::after {
  contain: "（必須）";
  font-size: 14px;
  color: #de2222;
}

.full-width--mobile {
  @include respond-below(xs) {
    margin: 0 -18px;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.bg-light-gray {
  background-color: $bg-light-gray;
}

.Select {
  position: relative;
  font-size: 16px;
  width: 100%;
  height: 40px;
  background: #fff;
  border-radius: 4px 4px 0 0;
  border: 1px solid #ccc;
  border-bottom-width: 2px;

  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);

  .line {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -2px;
    height: 2px;
    background: $blue;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: scaleX(0.85);
    opacity: 0;
  }

  & .active ~ .line {
    transform: scaleX(1);
    opacity: 1;
  }
}

.radio {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;

  input[type="radio"] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .mr-2 {
    cursor: pointer;
  }

  *:checked + .circle {
    border: none;

    &::after {
      border: 1px solid $blue;
    }

    &::before {
      background-color: $blue;
    }
  }

  .circle {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: "";
      position: absolute;
      border: 1px solid #ccc;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: none;
      transform: translate(-50%, -50%);
    }
  }
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-right: 10px;

  input[type="checkbox"] {
    position: absolute;
    width: 22px;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  & *:checked + .checkbox--content {
    background: #5f75ff;
    border-color: #1d5fda;
  }

  &--content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background: #fff;
    border: 2px solid #ccc;
    border-radius: 2px;
    transition: border 15ms cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
  }
}

.Input {
  input {
    position: relative;
    font-size: 16px;
    height: 56px;
    padding: 16px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #ccc;
    border-bottom-width: 2px;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: $blue;
      transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
      transform: scaleX(0.85);
      opacity: 0;
    }
  }

  & input:active ~ input::after,
  & input:focus ~ input::after,
  & textarea:active ~ textarea::after,
  & textarea:focus ~ textarea::after {
    transform: scaleX(1);
    opacity: 1;
  }
}

.top-page--reverse {
  @include respond-below(xs) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.modal-open {
  height: 100vh;
  overflow: hidden !important;
}

.border-b {
  border-bottom: 1px solid $light-gray;
}

.box-shadow {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.28);
}

.no-pointer {
  cursor: auto;
}

.mb-24 {
  margin-bottom: 24px;
}

.css-g1d714-ValueContainer {
  .css-1uccc91-singleValue {
  }
}

.reverse_custom_first {
  @include respond-below(xs) {
    order: 2;
  }
}

.reverse_custom_second {
  @include respond-below(xs) {
    order: 1;
  }
}
.reverse_custom_third {
  @include respond-below(xs) {
    order: 3;
  }
}