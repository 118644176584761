$breakpoints: (
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px
);

@mixin respond-below($breakpoint){
    @if map-has-key($breakpoints, $breakpoint){

        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (max-width: $breakpoint-value){
            @content;
        }
    }
}

@mixin respond-above($breakpoint){
    @if map-has-key($breakpoints, $breakpoint){

        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (min-width: $breakpoint-value){
            @content;
        }
    }
}