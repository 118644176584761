// Key Color
$primary: #2a9b3c;
$primary-text-color: #000000;
$secondary-text-color: #777777;
$link-color: #4285f4;
$not-exist-color: #bfbfbf;

$white: #ffffff;

$red: #de2222;

$dark-gray: #777777;
$gray: #707070;
$light-gray: #eaeaea;
$gray-500: #555555;
$gray-bg-checkbox: #e8e8e8;

$border-color: #cccccc;
$border-color2: #555555;
$line-color: #eaeaea;
$line-frm-color: #495057;

$blue: #3e7bed;
$light-blue: #bfd5ff;
$black: #000000;

$bg-color: #ececf2;
$bg-color-form-title: #ececf2;
$bg-section: #dbdbdb;
$bg-color-add-btn: #f7f7fd;
$bg-dark-gray-btn: #a3a7af;
$bg-light-gray: #f7f7f7;
$bg-color-time-not-found: #f2f2f2;

$disable-text: #cbcbcb;
$scroll-bar: #c1c1c1;
$scroll-bar-hover: #a8a8a8;
$outline: #b9d8f9;
$outline-border: #80bdff;

$border-date-selected: #216ba5;
$schedule-change: #168428;

$placeholder-text: #6c757d;
$table-first-row: #f9fbfb;
$row-even: #f8f8f8;
$table-border: #ccdcda;

$important-note-back: #FCF4F4;
$important-note-border: #EDC7C7;