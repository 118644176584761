@import "styles/scss/Color.scss";
@import "styles/scss/_media-queries.scss";

.Toastify .Toastify__toast-container {
  top: 69px;
  left: 50%;
  transform: translateX(-50%);

  @include respond-below(xs) {
    top: 0px !important;
  }
}

.Toastify {
  &__toast {
    background: $blue !important;
    color: $white !important;
    padding: 0 !important;
    padding-left: 15px !important;

    @include respond-below(sm) {
      min-height: 56px !important;
    }
  }

  &__close-button {
    display: none;
  }

  &__toast-body {
    text-align: left;
  }
}

.Toastify__toast--info {
  background: #3498db !important;
}

.Toastify__toast--warning {
  background: #f1c40f !important;
}
.Toastify__toast--error {
  background: #e74c3c !important;
}

.fadeIn {
  animation: fadeIn 0.2s ease-in-out;
}

.fadeOut {
  animation: fadeOut 0.2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
