@import "styles/scss/_media-queries.scss";
@import "styles/scss/Color.scss";

.pt-27 {
  padding-top: 27px !important;
}

button:focus {
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.75);
  border: none;
  outline: none;
}

.btn {
  box-shadow: none;

  &:focus {
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.75);
  }

  &-blue {
    background-color: $blue;
    color: $white !important;
  }

  &-red {
    background-color: $red;
    color: $white !important;
  }

  &-green {
    background-color: $primary;
    color: $white !important;
  }

  &-green--outline {
    background: $white !important;
    color: $primary !important;
    border: 1px solid $primary;
  }

  &-outline-green {
    background-color: $white;
    border: 1px solid $primary;
  }

  &-gray {
    background-color: $bg-light-gray;
    color: white !important;
  }

  &-dark-gray {
    background-color: $bg-dark-gray-btn;
    color: white !important;
  }
}

.add-btn {
  min-height: 72px;
  padding-left: 34px;
  padding-right: 34px;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    font-size: 18px;

    @include respond-below(md) {
      font-size: 16px;
      align-self: center;
      width: 100%;
      text-align: center;
    }

    @media (max-width: 375px) {
      padding-right: 16px;
    }

    @media (max-width: 320px) {
      margin-left: 0px;
      padding-right: 8px;
    }
  }

  img {
    width: 28px;
    height: 28px;
    z-index: 1;
    margin-right: 40px;

    @include respond-below(md) {
      position: absolute;
      left: 32px;
      top: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    @media (max-width: 375px) {
      margin-right: 16px;
      margin-left: 16px;
      margin-top: -3px;
      position: static;
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }

    @media (max-width: 320px) {
      margin-left: 8px;
      margin-right: 8px;
      margin-top: -3px;
    }
  }

  @include respond-below(sm) {
    width: 100%;
    padding: 18px 16px;
    justify-content: start;
  }

  &__container {
    padding: 32px 0 32px;
    background-color: $bg-color-add-btn;

    @include respond-below(md) {
      padding-left: 18px;
      padding-right: 18px;
    }

    @include respond-below(sm) {
      padding: 20px 0;
    }
  }

  .btn__text {
    @include respond-below(md) {
      text-align: center;
      flex: 1;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      position: relative;

      @include respond-below(sm) {
        font-size: 16px;
      }
    }
  }
}

.delete-btn {
  background-color: $white;
  color: $red !important;
  border: 1px solid $border-color;
}

.big-btn {
  height: 56px;
  min-height: 48px;
  padding: 0 60px;
  width: 340px;
  border-radius: 4px;

  img {
    margin-right: 5px;
  }

  &.mw-100 {
    @include respond-below(xs) {
      width: 100%;
      padding: 0;
    }
  }

  @include respond-below(xs) {
    width: 100%;
    max-width: 340px;
    padding: 0;
  }
}

// .add-modal-btn:before {
//   content: url("../../../../assets/images/add-icon-desktop.png");
//   margin-right: 7px;
// }

.text-small {
  font-size: 14px;
}

.btn-green {
  &:hover {
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  }
}

.h-auto {
  &:hover {
    font-weight: bold;
  }
}
