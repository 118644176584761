@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./scss/Color";
@import "./scss/media-queries";
@import "./scss/Main";
@import "./scss/FixSafari";

* {
    box-sizing: border-box;
}

.error-text {
    font-size: 100%;
    color: red;
}

.norisuke
// ,html
{
    overflow-x: hidden;
}

.body-modal-open {
    height: 100vh;
    position: fixed;
    overflow: hidden !important;
}

.body-modal {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    @include respond-below(md) {
        width: 100%;
    }
}

html {
    width: 100vw;
    box-sizing: content-box;
}

#root {
    min-height: 100vh;
    height: 100%;
    width: 100vw;

    @include respond-below(md) {
        width: 100%;
    }

    display: flex;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    width: 100vw;
    height: 100vh;
    font-family: Hiragino Kaku Gothic ProN; //-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $bg-color;
    overflow-x: hidden;

    .modal-open {
        overflow: hidden;
        position: fixed;
        -webkit-overflow-scrolling: auto;
    }

  
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body.modal-open[style] {
    padding-right: 0 !important;
}

*:focus {
    outline: solid;
    outline-color: $outline;
}

.multi-line {
    word-break: break-all;
    white-space: pre-line;
}

.invisible-text {
    color: transparent;
    margin: 0;
}

.image-contain {
    object-fit: contain;
    object-position: 0 0;
}

.norisuke-form-explain-url, .norisuke-form-explain-url:hover {
    text-decoration: underline;
}
