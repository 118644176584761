.login-form {
  display: flex;
  justify-content: center;
}

.ass1-login__content {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 22px;
    text-align: center;
    padding-top: 5em;
    position: relative;
    padding-bottom: 0.84em;
    font-weight: 500;

    &::after {
      content: "";
      position: absolute;
      width: 30px;
      height: 1px;
      background-color: #ccc;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__google--button--container {
    float: none;
    position: static;
    display: block;
    width: max-content;
  }

  &__google--button {
    background-color: #3e7bed !important;
    color: #fff !important;
    margin-top: 1.28rem;
    width: 21.25rem !important;
    height: 64px !important;
    font-size: 16px !important;

    svg {
      transform: scale(1.55);
    }

    div {
      width: 64px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__require {
    padding-top: 1em;
    text-align: start;
    font-size: 12px;
  }
}

.google-effect {
  position: relative;
  padding: 0;
  margin-top: 20.48px;
}

button.google-login,
button.google-login * {
  cursor: pointer !important;
  margin: 0;
  background: none;
}
